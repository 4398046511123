<template>
  <div class="agentList">
    <div class="header">
      <h6>经纪人列表</h6>
      <div class="btn-wrap">
        <p class="btn"
           v-role="['administrator']"
           @click="addClick">
          <i class="el-icon-plus btn"></i>
          <span>新增</span>
        </p>
        <p class="btn"
           @click="exportClick">
          <i class="icon iconfont icon-daochu btn"></i>
          <span>导出</span>
        </p>
      </div>
    </div>
    <el-card style="margin-top:20px">
      <el-row :gutter="20">
        <el-form :inline="true"
                 :size="'small'"
                 :model="formInline"
                 class="demo-form-inline"
                 ref="form">
          <el-col :span="6">
            <el-form-item label="手机号:"
                          prop="phone">
              <el-input v-model="formInline.phone"
                        placeholder="请输入要查询的手机号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="姓名:"
                          prop="name">
              <el-input v-model="formInline.name"
                        placeholder="请输入要查询的姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="邀请人"
                          prop="inviter">
              <brokerSelect @clearBrokerSelect="clearBrokerSelect"
                            @selectTreeChange="selectTreeChange"
                            :placeholder="'请选择邀请人'"></brokerSelect>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="归属人"
                          v-role="['administrator']"
                          prop="plate_form">
              <belonger @belongerSeChange="belongerSeChange"></belonger>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item>
              <el-button type="primary"
                         @click="searchClick"
                         icon="el-icon-search">搜索</el-button>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <tablePage :tableData="tableData"
                 :currentPage="formInline.page"
                 :height="tableHeight"
                 @sort-change="tableSortChange"
                 @current-change="currentChange"
                 v-loading="loading"
                 ref="tableList"
                 :total="total">
        <el-table-column label="基础信息"
                         min-width="240">
          <template slot-scope="scope">
            <div class="base-wrap">
              <div class="avatar-wrap">
                <span class="avatar-role"
                      v-show="scope.row.role === 'staff'">员工</span>
                <span class="avatar-role blue"
                      v-show="scope.row.role === 'broker'">已认证</span>
                <span class="avatar-role red"
                      v-show="scope.row.role === 'personal'">未认证</span>
                <span class="avatar-role orange"
                      v-show="scope.row.role === 'manager'">代理</span>
                <colorfulAvatar :isBroker="true"
                                :name="scope.row.name"></colorfulAvatar>
              </div>
              <div class="text-wrap">
                <p>{{ scope.row.name }}</p>
                <p>{{ scope.row.phone }}</p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="其他信息"
                         min-width="180">
          <template slot-scope="scope">
            <p>邀请人：{{ scope.row.inviter_name }}</p>
            <p>直属主播：{{scope.row.anchor_count}}</p>
            <p>邀请经纪人：{{ scope.row.distributor_count }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="currentMonth"
                         :sortable="'custom'"
                         min-width="180">
          <template slot="header">
            <span>分成比/<span :style="isSort ? {'color':'#d74d38'}:{}">本月流水</span></span>
          </template>
          <template slot-scope="scope">
            <p>分成比：{{ scope.row.separate_ratio }}%</p>
            <p>本月流水：{{ scope.row.month_flow }}</p>
          </template>
        </el-table-column>
        <el-table-column v-if="userInfo && userInfo.role === 'administrator'"
                         prop="plate_form"
                         min-width="160"
                         label="经办人"></el-table-column>
        <el-table-column prop="coins"
                         :sortable="'custom'"
                         min-width="150"
                         label="本月收益余额">
        </el-table-column>
        <el-table-column prop="diamonds"
                         :sortable="'custom'"
                         min-width="150"
                         label="上月收益余额">
        </el-table-column>
        <el-table-column prop="history"
                         label="钱包"></el-table-column>
        <el-table-column prop="created"
                         min-width="160"
                         label="注册时间"></el-table-column>
        <el-table-column min-width="190"
                         label="完成度">
          <template slot-scope="scope">
            <p>有效职业主播完成度:{{ scope.row.effective_anchor_pro_progress }}</p>
            <p>有效主播完成度:{{ scope.row.effective_anchor_progress }}</p>
          </template>
        </el-table-column>
        <el-table-column v-if="role(['administrator','manager'])"
                         label="操作"
                         min-width="120">
          <template slot-scope="scope">
            <span class="handle edit"
                  @click="editClick(scope.row)"
                  v-if="role(['administrator','manager'])">编辑</span>
            <span class="handle del"
                  @click="delClick(scope.row)"
                  v-if="role(['administrator'])">删除</span>
            <el-dropdown>
              <span class="el-dropdown-link">
                修改权限
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-if="role(['administrator'])"
                                  @click.native="anchorContract(scope.row)">{{`${scope.row.has_contract == 1 ? '解约': '签定'}合同`}}</el-dropdown-item>
                <el-dropdown-item v-if="role(['administrator','manager'])"
                                  @click.native="anchorAuth(scope.row)">{{`${scope.row.can_create_anchor ? '关闭': '开启'}添加主播权限`}}</el-dropdown-item>
                <el-dropdown-item v-if="role(['administrator','manager'])"
                                  @click.native="areaAuth(scope.row)">{{`${scope.row.not_limit_visit ? '开启' : '关闭'}地区限制`}}</el-dropdown-item>
                <!-- <el-dropdown-item v-if="role(['administrator'])"
                                  @click.native="flowTicketChange(scope.row)">修改剩余流量券</el-dropdown-item> -->
                <el-dropdown-item v-if="role(['administrator','manager'])"
                                  @click.native="changeBalance(scope.row)">修改余额</el-dropdown-item>
                <el-dropdown-item v-if="role(['administrator','manager'])"
                                  @click.native="withdrawAuth(scope.row)">{{`${scope.row.can_withdraw ? '关闭' : '开启'}提现权限`}}</el-dropdown-item>
                <!-- <el-dropdown-item v-if="role(['administrator'])"
                                  @click.native="divideRatio(scope.row)">修改新增主播奖励分成比</el-dropdown-item> -->
                <el-dropdown-item v-if="role(['administrator','manager'])"
                                  @click.native="anchorPackage(scope.row)">{{`${scope.row.can_separate_permission ? '关闭': '开启'}打包结算`}}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
        <el-table-column v-if="role(['administrator','manager'])"
                         label="权限"
                         min-width="140"
                         fixed="right">
          <template slot-scope="scope">
            <el-tag size="mini"
                    :type="scope.row.has_contract===1 ? 'success': ''">{{scope.row.has_contract===1 ? '已签合同':'未签合同'}}</el-tag>
            <el-tag size="mini"
                    :type="scope.row.can_create_anchor ? 'success': ''">添加主播</el-tag>
            <el-tag size="mini"
                    :type="scope.row.not_limit_visit ? '': 'success'">地区限制</el-tag>
            <el-tag size="mini"
                    :type="scope.row.can_withdraw ? 'success': ''">提现权限</el-tag>
            <el-tag size="mini"
                    :type="scope.row.can_separate_permission ? 'success': ''">打包结算</el-tag>
          </template>
        </el-table-column>
      </tablePage>
    </el-card>
    <delAgentModal @submitDelDialog="submitDelDialog"
                   @cancleDialog="cancleDialog"
                   :agentName="agentName"
                   :delDialogVisible="delDialogVisible"></delAgentModal>
    <flowDialog ref="flow"
                @submitFlowDialog="submitFlowDialog"
                @cancleDialog="cancleDialog"
                :flowDialogVisible="flowDialogVisible"></flowDialog>
    <divideModal @submitDivideDialog="submitDivideDialog"
                 @cancleDialog="cancleDialog"
                 :divideDialogVisible="divideDialogVisible"></divideModal>
    <balanceDialog ref="balance"
                   @submitBalanceDialog="submitBalanceDialog"
                   @cancleDialog="cancleDialog"
                   :balanceDialogVisible="balanceDialogVisible"></balanceDialog>
  </div>
</template>

<script>
import tablePage from "components/tablePage";
import brokerSelect from "components/brokerSelect";
import belonger from "components/belonger";
import delAgentModal from "./components/delAgentModal";
import flowDialog from "./components/flowDialog";
import divideModal from "./components/divideModal";
import balanceDialog from "./components/balanceDialog";
import tableHeight from "mixins/tableHeight";
import colorfulAvatar from "components/colorfulAvatar";
import api from "@/api";
import { mapState } from "vuex";
export default {
  components: {
    tablePage,
    brokerSelect,
    belonger,
    delAgentModal,
    flowDialog,
    divideModal,
    balanceDialog,
    colorfulAvatar,
  },
  mixins: [tableHeight],
  data () {
    return {
      balanceDialogVisible: false,
      divideDialogVisible: false,
      flowDialogVisible: false,
      delDialogVisible: false,
      agentName: "",
      agentId: "",
      agentRole: "",
      loading: true,
      formInline: {
        phone: "",
        name: "",
        inviter: "",
        plate_form: "",
        page: 1,
      },
      inviterList: [
        {
          id: 1,
          label: "小明",
        },
      ],
      belongerList: [
        {
          id: 1,
          label: "小红",
        },
      ],
      tableData: [],
      total: 10,
      isSort: false
    };
  },
  created () {
    this._initState();
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  methods: {
    tableSortChange ({ prop, order }) {
      if (order) {
        let aprop
        prop === 'currentMonth' ? aprop = 'month_flow' : aprop = prop
        prop === 'currentMonth' ? this.isSort = true : this.isSort = false
        this.formInline.order = `${aprop},${order === 'descending' ? order.substr(0, 4) : order.substr(0, 3)}`
        this._initState({ ...this.formInline })
      } else {
        this.isSort = false
      }
    },
    async submitBalanceDialog (form) {
      const { status_code, message } = await api.walletTransferApi(
        Object.assign(
          { ...form },
          { user_id: this.agentId, user_type: 'broker' }
        )
      );
      this.notice(status_code, "修改成功", "修改失败", message);
      this.balanceDialogVisible = false;
      this.$refs.balance.$refs.form.resetFields();
    },
    changeBalance ({ id, role }) {
      this.agentId = id;
      this.agentRole = role;
      this.balanceDialogVisible = true;
    },
    async submitDivideDialog (msg) {
      const { status_code } = await api.increaseRatioApi(
        Object.assign({ ...msg }, { broker_id: this.agentId })
      );
      this.notice(status_code, "修改成功", "修改失败");
      this.divideDialogVisible = false;
    },
    divideRatio ({ id }) {
      this.divideDialogVisible = true;
      this.agentId = id;
    },
    clearBrokerSelect () {
      this.formInline.inviter = "";
    },
    async submitFlowDialog (msg) {
      const { status_code, message } = await api.flowTicketApi(
        Object.assign({ ...msg }, { broker_id: this.agentId })
      );
      this.notice(status_code, "修改成功", "修改失败", message);
      this.flowDialogVisible = false;
      this.$refs.flow.clearData();
    },
    flowTicketChange ({ id }) {
      this.flowDialogVisible = true;
      this.agentId = id;
    },
    cancleDialog (msg) {
      this[msg] = false;
    },
    notice (status_code, message, errMsg = "失败，请重试", data) {
      if (status_code === 200) {
        this.$message({
          type: "success",
          message,
        });
        this._initState({ ...this.formInline });
      } else if (status_code === 422) {
        Object.keys(data).map(item => {
          this.$message.error({
            message: data[item]
          })
        })
      } else {
        this.$message({
          type: "error",
          errMsg,
        });
      }
    },
    async permissionFn (id, permission, type) {
      const { status_code, message } = await api.areaAuthApi({
        id,
        permission,
        type: type ? false : true,
      });
      this.notice(status_code, message);
    },
    //签约
    async anchorContract ({ id, has_contract }) {
      const { status_code, message } = await api.contractAuthApi({
        broker_id: id,
        status: 1 ^ has_contract,
      });
      this.notice(status_code, message);
    },
    areaAuth ({ not_limit_visit, id }) {
      this.permissionFn(id, "visit_in_limit_city", not_limit_visit);
    },
    withdrawAuth ({ can_withdraw, id }) {
      this.permissionFn(id, "withdraw", can_withdraw);
    },
    //打包结算
    async anchorPackage ({ can_separate_permission, id }) {
      console.log(can_separate_permission ? 0 : 1);
      const { status_code, message } = await api.packageAuthApi({
        broker_id: id,
        can_settle_account: can_separate_permission ? 0 : 1,
      });
      this.notice(status_code, message);
    },
    async anchorAuth ({ can_create_anchor, id }) {
      const { status_code, message } = await api.areaAuthApi({
        id,
        permission: 'create_anchor',
        type: can_create_anchor ? false : true
      });
      this.notice(status_code, message);
    },
    searchClick () {
      this.formInline.page = 1;
      this._initState({ ...this.formInline });
    },
    belongerSeChange (id) {
      this.formInline.plate_form = id;
    },
    selectTreeChange (id) {
      this.formInline.inviter = id;
    },
    async _initState (params = {}) {
      const {
        status_code,
        meta: {
          pagination: { total },
        },
        message,
      } = await api.brokerListApi(params);
      if (status_code === 200) {
        this.total = total;
        this.tableData = message;
        this.loading = false;
      }
    },
    addClick () {
      this.$router.push({ path: "/agentmanage/agentlist/agentadd" });
    },
    currentChange (page) {
      this.formInline.page = page;
      this._initState({ ...this.formInline });
    },
    editClick (row) {
      this.$router.push({
        name: "agentedit",
        params: row,
      });
    },
    async submitDelDialog () {
      const { status_code } = await api.brokerDelApi({ id: this.agentId });
      this.notice(status_code, "删除成功", "删除失败");
      this.delDialogVisible = false;
    },
    delClick ({ id, name }) {
      this.delDialogVisible = true;
      this.agentName = name;
      this.agentId = id;
    },
    exportClick () {
      this.$excelDownload(this.formInline, "api/broker/broker-export");
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  display: flex;
  justify-content: space-between;
}
h6 {
  font-size: 20px;
  color: #333333;
  font-weight: 500;
}
.btn {
  line-height: 20px;
  font-size: 16px;
  color: #d74d38;
  cursor: pointer;
  span {
    display: inline-block;
    margin-left: 4px;
  }
}
.btn + .btn {
  margin-left: 15px;
}
.icon {
  font-size: 14px;
  color: #d74d38;
}
.btn-wrap {
  display: flex;
}
.base-wrap {
  display: flex;
  .text-wrap {
    margin-left: 10px;
    margin-top: 12px;
  }
  p {
    font-size: 14px;
    color: #333333;
  }
}
.handle {
  font-size: 14px;
  cursor: pointer;
}
.edit {
  color: #60c3f7;
}
.del {
  color: #666666;
  line-height: 14px;
  border-left: 1px solid #e3e3e3;
  padding-left: 12px;
  margin-left: 12px;
}
.el-dropdown-link {
  cursor: pointer;
  color: #d74d38;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 20px;
}
.avatar-wrap {
  position: relative;
  height: 64px;
  border-radius: 50%;
  overflow: hidden;
}
.avatar-role {
  display: block;
  width: 64px;
  height: 20px;
  text-align: center;
  color: #ffffff;
  line-height: 20px;
  font-size: 12px;
  background-color: #fab000;
  position: absolute;
  left: 0;
  bottom: 0px;
  z-index: 2;
}
.blue {
  background: #60c3f7;
}
.red {
  background: #d74d38;
}
.orange {
  background: orange;
}
.agentList /deep/ .el-tag.el-tag--success {
  background-color: #eff9fe;
  border-color: #eff9fe;
  color: #60c3f7;
}
.agentList /deep/.el-dialog {
  margin-top: 0vh !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
}
</style>